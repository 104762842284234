import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './BlogList.css'; // We'll create this CSS file

function parseFrontmatter(content) {
  const frontmatterRegex = /^---\s*([\s\S]*?)\s*---/;
  const match = frontmatterRegex.exec(content);
  if (!match) return { attributes: {}, content };

  const frontmatter = match[1];
  const attributes = {};
  frontmatter.split('\n').forEach(line => {
    const [key, ...rest] = line.split(':');
    if (key && rest.length) {
      attributes[key.trim()] = rest.join(':').trim();
    }
  });

  const contentWithoutFrontmatter = content.replace(frontmatterRegex, '').trim();
  return { attributes, content: contentWithoutFrontmatter };
}

// Blog posts go from most recent --> least recent (add new ones at top)
const blogSlugs = [
  'why-the-insurance-industry-is-dangerously',
  'analyzing-the-knowbe4-deepfake-hiring',
  'live-deepfake-attack-strikes-hong',
  'the-new-threat-on-the-block-for-enterprise',
  'using-someone-elses-voice-how-simple',
  'current-deepfake-trends-and-their',
  'our-approach-to-preventing-deepfake',
  'coinbase-ceo-deepfake-plagues-youtube',
  'ai-cannot-be-used-to-fight-ai',
];

const BlogList = () => {
  const [blogPosts, setBlogPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      const posts = await Promise.all(
        blogSlugs.map(async (slug) => {
          const response = await fetch(`/blogPosts/${slug}.md`);
          const content = await response.text();
          console.log(`Content for ${slug}:`, content.substring(0, 100));
          const { attributes, content: parsedContent } = parseFrontmatter(content);
          return {
            slug,
            ...attributes,
            content: parsedContent
          };
        })
      );
      setBlogPosts(posts);
    };

    fetchPosts();
  }, []);

  return (
    <div className="blog-list-container">
      <h1 className="blog-list-title">Blog Posts</h1>
      <div className="blog-grid">
        {blogPosts.map(post => (
          <div key={post.slug} className="blog-card">
            <h2 className="blog-card-title">{post.title || 'No Title'}</h2>
            <p className="blog-card-date">{post.date || 'No Date'}</p>
            <p className="blog-card-excerpt">{post.excerpt || 'No Excerpt'}</p>
            <Link to={`/blog/${post.slug}`} className="blog-card-link">Read More</Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogList;
