import React from "react";
import { useTranslation } from 'react-i18next';

function ScrollingStats() {
    const { t } = useTranslation();
    return (
        <section className="key-stats">
            <h2 >{t('whyShouldYouProtect')}</h2>
            <div className="stats-container">
                <div className="stats-scroll">
                    <div className="stat-card">
                        <div className="stat-number">90<span>%</span></div>
                        <p>{t('stat1')}</p>
                    </div>
                    <div className="stat-card">
                        <div className="stat-number">80<span>%</span></div>
                        <p>{t('stat2')}</p>
                    </div>
                    <div className="stat-card">
                        <div className="stat-number">36<span>%</span></div>
                        <p>{t('stat3')}</p>
                    </div>
                    <div className="stat-card">
                        <div className="stat-number">40<span>B</span></div>
                        <p>{t('stat4')}</p>
                    </div>
                    <div className="stat-card">
                        <div className="stat-number">26<span>%</span></div>
                        <p>{t('stat5')}</p>
                    </div>
                    <div className="stat-card">
                        <div className="stat-number">52<span>%</span></div>
                        <p>{t('stat6')}</p>
                    </div>
                    <div className="stat-card">
                        <div className="stat-number">{'<50'}<span>%</span></div>
                        <p>{t('stat7')}</p>
                    </div>
                    <div className="stat-card">
                        <div className="stat-number">52<span>%</span></div>
                        <p>{t('stat8')}</p>
                    </div>
                    <div className="stat-card">
                        <div className="stat-number">90<span>%</span></div>
                        <p>{t('stat1')}</p>
                    </div>
                    <div className="stat-card">
                        <div className="stat-number">80<span>%</span></div>
                        <p>{t('stat2')}</p>
                    </div>
                    <div className="stat-card">
                        <div className="stat-number">36<span>%</span></div>
                        <p>{t('stat3')}</p>
                    </div>
                    <div className="stat-card">
                        <div className="stat-number">40<span>B</span></div>
                        <p>{t('stat4')}</p>
                    </div>
                    <div className="stat-card">
                        <div className="stat-number">26<span>%</span></div>
                        <p>{t('stat5')}</p>
                    </div>
                    <div className="stat-card">
                        <div className="stat-number">52<span>%</span></div>
                        <p>{t('stat6')}</p>
                    </div>
                    <div className="stat-card">
                        <div className="stat-number">{'<50'}<span>%</span></div>
                        <p>{t('stat7')}</p>
                    </div>
                    <div className="stat-card">
                        <div className="stat-number">52<span>%</span></div>
                        <p>{t('stat8')}</p>
                    </div>
                    {/* Duplicate cards for seamless looping */}
                    {/* ... (repeat the same structure for duplicated cards) */}
                </div>
            </div>
        </section>
    )
}

export default ScrollingStats;