import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './components/Home';
import ScheduleDemoPage from './components/ScheduleDemo';
import ThankYou from './components/ThankYou';
import BlogList from './components/BlogList';
import BlogPost from './components/BlogPost';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

// External redirect component
const ExternalRedirect = ({ to }) => {
  useEffect(() => {
    window.location.href = to;
  }, [to]);

  return <div>Redirecting...</div>;
};


function App() {
  return (
    <Router>
      <div className="App">
        <Header/>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/demo" element={<ScheduleDemoPage />} />
          <Route path="/thanks" element={<ThankYou />} />
          <Route path="/blog" element={<BlogList />} />
          <Route path="/blog/:slug" element={<BlogPost />} />
          <Route path="/terms" element={<ExternalRedirect to="https://app.termly.io/document/terms-of-service/970ce6ce-a4fb-4f2e-b3af-cac13b1b668f" />} />
          <Route path="/privacy" element={<ExternalRedirect to="https://app.termly.io/document/privacy-policy/78de9a51-4bc1-47e2-95bf-465ff0cb2b39" />} />
        </Routes>
        <Footer/>
      </div>
    </Router>
  );
}

export default App;
