import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import FlagLanguageSelector from './LanguageSelector';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const { t } = useTranslation();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="App-header">
      <div className="logo-container">
        <Link to="/">
          <img src="/validia_logo.png" alt="IdentifAI Logo" className="logo" />
        </Link>
      </div>
      <button className="menu-toggle" onClick={toggleMenu} aria-label="Toggle menu">
        {menuOpen ? <FaTimes /> : <FaBars />}
      </button>
      <nav className={menuOpen ? 'show' : ''}>
        <ul className={`nav-list ${menuOpen ? 'mobile-menu' : ''}`}>

          <li><a href="/#product">{t('product')}</a></li>
          <li><a href="/#company">{t('aboutUs')}</a></li>
          <li><a href="/#testimonials">{t('testimonials')}</a></li>
          <li><a href="/blog">{t('blog')}</a></li>
          <li><a href="/#faqs">{t('faqs')}</a></li>
          <li><a href="/#contact">{t('contact')}</a></li>
          <li className="mobile-cta"><button className="secondary-button" onClick={() => window.location.href = 'https://app.identifai.info'}>{t('signin')}</button></li>
          <li className="mobile-cta"><button className="primary-button" onClick={() => window.location.href = '/demo'}>{t('getDemo')}</button></li>
          {menuOpen && <li className="mobile-language-selector"><FlagLanguageSelector /></li>}
        </ul>
      </nav>
      <div className="cta-buttons desktop-cta">
        <Button variant="outlined" disableElevation className="secondary-button" onClick={() => window.location.href = 'https://app.identifai.info'}>{t('signin')}</Button>
        <Button variant="contained" disableElevation className="primary-button" onClick={() => window.location.href = 'https://calendly.com/d/494-3xs-7yt/identifai-30-minute-meeting'}>{t('talkToSomeoneReal')}</Button>
      </div>
      <div className="desktop-language-selector">
        <FlagLanguageSelector />
      </div>
    </header>
  );
}

export default Header;
