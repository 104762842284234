import React from "react";

function ArcadeEmbed() {
    return (
        <div style={{ position: 'relative', marginBotton: '10px', paddingBottom: 'calc(49.832589285714285% + 41px)', maxHeight: '10px', width: '100%' }}>
            <iframe src="https://demo.arcade.software/XQP2kJdm7fhtBEHGCfYO?embed&embed_mobile=tab&embed_desktop=inline&show_copy_link=true" title="IdentifAI" frameBorder="0" loading="lazy" allowFullScreen allow="clipboard-write" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', colorScheme: 'light' }} />
        </div>
    );
}

export default ArcadeEmbed;